<template>
  <b-card no-body>
    <b-card-header class="d-flex align-items-center justify-content-between">
      <b-card-title class="d-flex align-items-center">
        Recurring Log
      </b-card-title>
      <b-button
        variant="primary"
        @click="downloadLog"
      >
        <feather-icon
          icon="DownloadIcon"
          size="16"
        />
        Download Log
      </b-button>
    </b-card-header>
    <table class="table b-table">
      <tr
        role="row"
        class="table-white-space"
      />
      <th
        role="columnheader"
        scope="col"
        aria-colindex="1"
        class=""
      > Date</th>
      <th
        role="columnheader"
        scope="col"
        aria-colindex="2"
        class=""
      > Status</th>
      <tr
        v-for="logEntry in sortedDatesLog"
        :key="logEntry.date"
      >
        <td>{{ formatDate(logEntry.date) }}</td>
        <td>{{ logEntry.status }}</td>
      </tr>
    </table>
  </b-card>
</template>

<script>
import moment from 'moment'

import {
  BButton,
  BCardHeader,
  BCardTitle,
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCardTitle,
    BCardHeader,
    BCard,
  },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    transactions: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      datesLog: [],
    }
  },
  computed: {
    sortedDatesLog() {
      return this.datesLog.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
    },
  },
  watch: {
    transactions(newTransactions) {
      this.createTransactionLog(newTransactions)
    },
  },
  mounted() {
    this.createTransactionLog(this.transactions)
    if (this.detailData?.subscription_recurring_cancelled_at) {
      this.createRecurringLog()
    }
  },
  methods: {
    createTransactionLog(transactions) {
      const successfulEntries = transactions.filter(
        entry => entry.payment_status === 'SUCCEED',
      ).map(entry => ({
        date: entry.created_at,
        status: 'Renew',
      }))
      this.datesLog = [...this.datesLog, ...successfulEntries]
    },
    createRecurringLog() {
      const cancellationEntry = {
        date: this.detailData.subscription_recurring_cancelled_at,
        status: 'Canceled',
      }
      this.datesLog.push(cancellationEntry)
    },
    formatDate(dateString) {
      return moment(dateString).format('DD/MM/YYYY HH:mm:ss')
    },
    downloadLog() {
      let textContent = `${this.detailData?.supporter_nickname} subscription to ${this.detailData?.creator_nickname}\nActive until ${this.formatDate(this.detailData?.subscription_end_date)}\n\n`

      this.sortedDatesLog.forEach((log, index) => {
        textContent += `${index + 1}. ${this.formatDate(log.date)} [${log.status}]\n`
      })

      const blob = new Blob([textContent], { type: 'text/plain;charset=utf-8;' })
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)

      const dateTimeStamp = moment().format('YYYY-MM-DD-HHmm')
      link.setAttribute('href', url)
      link.setAttribute('download', `recurring-log-${dateTimeStamp}.txt`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

  },
}
</script>

<style scoped>
</style>
