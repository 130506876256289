<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <FormView
            :data="subscriptionData"
            :fields="fields"
            @load="loadData"
          />
        </b-card>
        <SubscriptionLog
          v-if="subscriptionData && subscriptionData.subscription_type_latest === 'RECURRING'"
          :detail-data="subscriptionData"
          :transactions="transactionData"
        />
      </b-col>
      <b-col>
        <b-card
          style="min-height: 300px"
          no-body
        >
          <b-card-header>
            <b-card-title class="d-flex align-items-center">
              <feather-icon
                icon="ListIcon"
                size="18"
                class="mr-50"
              />
              Transaction
            </b-card-title>
          </b-card-header>

          <div
            v-if="!transactionData.length && isLoadingTransaction"
            class="pt-5 text-center"
          >
            <b-spinner
              class="mr-50"
              variant="primary"
            />
          </div>
          <b-list-group
            v-else
            flush
          >
            <b-list-group-item
              v-for="(item) in transactionData"
              :key="`service-${item.human_id}`"
              class="cursor-pointer"
              @click="showDetail(item)"
            >
              <SubscriptionItem
                :description="item.ui_description"
                :usecase="item.usecase"
                :type="item.usecase"
                :token="item.price"
                :price="item.price_as_charged"
                :currency="item.currency_as_charged"
                :status="item.payment_status"
                :created-at="item.created_at"
                :started-at="item.subscription_start_date"
                :ended-at="item.subscription_end_date"
                :supporter-username="item.buyer_user_nickname"
                :supporter-id="item.buyer_user_id"
              />
            </b-list-group-item>
            <b-list-group-item
              v-if="!transactionData.length"
              disabled
            >
              No data available
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      :visible="viewDataModalVisible"
      title="View detail"
      ok-title="Done"
      ok-only
      @hidden="viewDataModalVisible = false"
    >
      <FormView
        :data="defaultDetail"
        :fields="transactionFields"
        lazy
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FormView from '@/layouts/components/FormView.vue'
import fields from '@/field/subscriptionV2'
import transactionFields from '@/field/transactionV2'

import {
  BCard,
  BRow,
  BCol,
  BCardHeader,
  BCardTitle, BListGroup, BListGroupItem, BSpinner, BModal,
} from 'bootstrap-vue'
import SubscriptionItem from '@/layouts/components/SubscriptionItem.vue'
import SubscriptionLog from '@/layouts/components/SubscriptionLog.vue'

export default {
  components: {
    BModal,
    BSpinner,
    BListGroupItem,
    SubscriptionItem,
    SubscriptionLog,
    BListGroup,
    BCardTitle,
    BCardHeader,
    BCard,
    BRow,
    BCol,
    FormView,
  },
  data() {
    return {
      // Fields
      fields,
      isLoadingTransaction: false,
      transactionData: [],

      viewDataModalVisible: false,
      defaultDetail: {},
      transactionFields,
    }
  },
  computed: {
    ...mapGetters({
      subscriptionData: 'subscriptionV2/detailData',
    }),
  },
  async mounted() {
    await this.loadTransactions()
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('subscriptionV2/getDetail', { id })
    },
    showDetail(item) {
      this.defaultDetail = {
        ...item,
        id: item.human_id,
        supporter: {
          id: item.buyer_user_id || item.usecase_data?.data?.subscriber_user_id,
          avatar: item.buyer_user_avatar_url,
          nickname: item.buyer_user_nickname || item.usecase_data?.data?.subscriber_user_nickname,
          email: item.buyer_user_email || item.usecase_data?.data?.subscriber_user_email,
        },
        creator: {
          id: item.seller_user_id || item.usecase_data?.data?.creator_user_id,
          avatar: item.seller_user_avatar_url,
          nickname: item.seller_user_nickname || item.usecase_data?.data?.creator_user_nickname,
        },
      }
      this.viewDataModalVisible = true
    },
    loadTransactions() {
      this.isLoadingTransaction = true

      this.$store.dispatch('transactionV2/getSubscriptionTransactionTableData', this.$route.params.id)
        .then(data => {
          this.transactionData = data
        })
        .finally(() => {
          this.isLoadingTransaction = false
        })
    },
  },
}
</script>

  <style scoped>

  </style>
